<template>
    <div class="HotArticle">
        <el-container>
            <el-aside width="150px">
                <ul>
                    <li @click="reload(item,index)" v-for="(item,index) in message" :key="index" :class="{check:checkIndex === index}">{{item}}</li>
                </ul>
            </el-aside>
            <el-main>
                <HotContent :KeyWords.sync="messageone" v-if="isShow && messageone"></HotContent>
            </el-main>
        </el-container>
    </div>
</template>
<script>
import HotContent from './HotArtivle/hot-list.vue'
export default {
    components:{
        HotContent
    },
    provide: function () {
        return {
            reload: this.reload,
        };
    },
    data(){
        return{
            message: [], //数据
            messageone:'',
            checkIndex:0,
            isShow:true,
        }
    },
    
    // mounted(){
        
    // },
    created(){
        this.searchDate();
    },
    methods:{
        //获取数据
        searchDate() {
            this.$get(this.$api.GetArticleCategoryData).then((res) => {
                console.log(res);
                var data = [];
                for(let i=0;i<res.Data.length;i++){
                    data[i] = res.Data[i].Name;
                }
                this.message = data;
                this.messageone = data[0];
            });
        },
        handleClick(tab) {
            this.sshow = false;
        },
        reload(name,index) {
            this.messageone = name;
            this.isShow = false;
            this.checkIndex = index;
            this.$nextTick(() => {
                this.isShow = true;
            });
        },
    }
  
 }
</script>
<style lang="stylus" scoped>
.HotArticle{
    // height 100%
    .el-container{
        background #fff
        // height 100%
    }
    .el-aside{
        ul{
            height 100%
            padding 20px 0
            li{
                color #3c3c3c
                font-size 16px
                text-align right
                height 30px
                line-height 30px
                padding-right 20px
                margin-bottom 10px
                cursor pointer
            }
            .check{
                color #409eff
                border-right 2px solid #409eff
            }
        }
    }
    .el-main{
        background #fff
        /deep/.el-tabs__content{
        }
    }
}
</style>