<template>
  <div class="hot-content">
    <div class="top">
      <span>{{ KeyWords }}</span>
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-width="100px"
      >
        <el-form-item label="文章标题:" prop="keyWords">
          <el-input
            v-model="searchForm.keyWords"
            placeholder="文章标题"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetForm('searchForm')">重置</el-button>
          <el-button type="primary" @click="search(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <el-table border :data="PageConfigure" row-key="Id" style="width: 100%">
      <el-table-column
        type="index"
        label="排序"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column label="文章标题" width="400" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.Title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图片" align="center">
        <template slot-scope="scope">
          <a :href="scope.row.ConverImageURL" target="_blank">
            <img
              :src="scope.row.ConverImageURL"
              style="width: auto; height: 50px"
            />
          </a>
        </template>
      </el-table-column>
      <el-table-column label="提示" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.SortNumber == 1 ? "序号为1,是广告位。" : "正常文章"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="序号" width="80" align="center">
        <template slot-scope="scope">
          <span style="font-weight: 700">{{ scope.row.SortNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column width="240" label="操作" align="center">
        <template slot-scope="scope">
          <span
            class="backSpan"
            @click="eduit(scope.row)"
            style="color: #409eff"
            >编辑</span
          >
          <router-link
            :to="{ name: 'Articleedit', query: { soid: scope.row.Id } }"
          >
            <span class="backSpan" style="color: #5fb878"
              >配置详细页侧边栏</span
            >
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[5, 10, 20, 30, 40, 50]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog
          :visible.sync="dialogFormVisible"
          custom-class="edit-form"
          :title="dialongtitle"
          :modal-append-to-body="false"
        >
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="文章:"
              :label-width="formLabelWidth"
              prop="Title"
            >
               <el-input v-model="Rowcontent.Title" disabled></el-input>
            </el-form-item>
            <el-form-item label="banner图片:" :label-width="formLabelWidth"
              ><span class="xingxing">*</span>
              <el-upload
                :show-file-list="false"
                :action="img_Url"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                @click="clickimg"
              >
                <el-button type="primary">
                  上传<i class="el-icon-upload el-icon--right"></i>
                </el-button>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="图片地址:"
              :label-width="formLabelWidth"
              prop="ConverImageURL"
            >
              <el-input
                v-model="Rowcontent.ConverImageURL"
                placeholder="图片地址"
                class="listinput"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="文章序号:"
              type="number"
              :label-width="formLabelWidth"
              prop="SortNumber"
            >
               <el-input
                v-model="Rowcontent.SortNumber"
                placeholder="请输入文章序号"
                maxlength="40"
              ></el-input>
            </el-form-item>
          </el-form>
              
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">返回</el-button>
            <el-button @click="submitForm('editForm')" :disabled="isDisabled">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Sortable from "sortablejs";
export default {
  props: {
    KeyWords: {
      type: String,
      default: String,
    },
  },
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^\+?[1-9][0-9]*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    };
    return {
      dialogFormVisible: false,
      dialongtitle: "",
      formLabelWidth: "100px",
      Rowcontent: {
        KeyWords: this.KeyWords,
        Id: "",
        Title: "",
        ConverImageURL: "",
        SortNumber: "",
      },
      ADcontent: {
        ArticleName: "",
        ArticleUrl: "",
        ArticleImg:"",
        KeyWords: this.KeyWords,
        SortNumber: 0,
      },
      PageConfigure: [], //列表分页数据
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image", //图片
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 0,
        TotalPage: 0,
        categoryName: this.KeyWords,
      },
      searchForm: {
        keyWords: "",
      },
      rules: {
        Title: [{ required: true, message: "请完善信息", trigger: "blur" }],
        ConverImageURL: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        SortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
      },
      adrules: {
        ArticleName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        ArticleUrl: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        ArticleImg: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
      },
      IsApp: false,
      IsEdit: false,
      pageconfigID: "",
      pageName: "",
      deleteStatus: false,
      //解决重复点击问题
      isDisabled: false,
    };
  },
  mounted() {
    this.rowDrop();
    this.searchDate();
    console.log(this.KeyWords);
  },
  methods: {
    uploadFile(file) {
      let nameIndex=file.name.lastIndexOf('.');
      let type=file.name.substring(nameIndex+1,file.name.length);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return this.$message.error('上传图片文件大小不能超过 2MB!');
      }
      var formData = new FormData();
      formData.append("file", file);

      this.$post(this.$api.UploadFileV2+'?module=adsense&fileExpand=.'+type,formData).then(res=>{
        if(res.code==200) {
          this.ADcontent.ArticleImg=res.data;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
   
    //刷新
    refresh() {
      this.$router.go(0);
    },
    //获取数据
    searchDate() {
      this.$get(this.$api.GetArticleList, {
        ...this.Params,
      }).then((res) => {
        console.log(res);
        this.PageConfigure = res.Data;
        this.Params.TotalCount = res.TotalNumber;
        this.Params.TotalPage = res.TotalPage;
      });
    },
    //查询
    search(num) {
      if(num==1){
          this.Params.PageIndex=1;
        }
      this.$get(this.$api.GetArticleList, {
        ...this.searchForm,
        ...this.Params,
      }).then((res) => {
        console.log(res);
        this.PageConfigure = res.Data;
        this.Params.TotalCount = res.TotalNumber;
        this.Params.TotalPage = res.TotalPage;
      });
    },
    //重置查询
    resetForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.searchForm.keyWords="";
      this.search(1);
    },

    //编辑
    eduit(val) {
      this.IsEdit = true;
      this.dialongtitle = "编辑";
      this.dialogFormVisible = true;
      console.log(val);
      this.Rowcontent.Title = val.Title;
      this.Rowcontent.ConverImageURL = val.ConverImageURL;
      this.Rowcontent.SortNumber = val.SortNumber;
      this.Rowcontent.Id = val.Id;
    },

    //防抖
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },

    //编辑的提交
    submitForm(editForm) {
      this.isDisabled = true;
      this.entime();
      var _this = this;
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            this.$post(
              this.$api.EditWebSideArticleCategory +
                "?id=" +
                this.Rowcontent.Id +
                "&&converImageURL=" +
                this.Rowcontent.ConverImageURL +
                "&&sortNumber=" +
                this.Rowcontent.SortNumber
            )
              .then((res) => {
                if (res.Code ==200) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                  
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },
    // 行拖拽
    rowDrop() {
      // 此时找到的元素是要拖拽元素的父容器
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        //  指定父元素下可被拖拽的子元素
        draggable: ".el-table__row",
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.PageConfigure.splice(oldIndex, 1)[0];
          _this.PageConfigure.splice(newIndex, 0, currRow);
          console.log("拖拽完成");
          console.log(_this.PageConfigure);
          _this
            .$post(_this.$api.UpdateSortnumber, _this.PageConfigure)
            .then((res) => {
              if (res.IsSuccess) {
                _this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.search();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.search();
    },
    //图片
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.Rowcontent.ConverImageURL = res.success_list[0];
    },
    beforeAvatarUpload() {},
    clickimg() {},
  },
};
</script>

<style lang="stylus" scoped>
.avatar-uploader .el-upload {
  width: 320px;
  height: 140px;
  border: 1px dashed black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  border: 1px dashed black;
}
.avatar {
  width: 320px;
  height: 140px;
  display: block;
  background-size: cover;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.hot-content {
  .top {
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-left: 15px;
    }
  }

  .backSpan {
    padding: 0px 10px;
    cursor: pointer;
  }

  .right-top {
    margin-bottom: 10px;

    button {
      margin-left: 10px;
    }
  }

  .task-list-page {
    margin-top: 10px;
  }
}

.xingxing {
  color: red;
  position: absolute;
  margin-left: -100px;
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
</style>