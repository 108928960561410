import { render, staticRenderFns } from "./Hot-article.vue?vue&type=template&id=2be6252a&scoped=true&"
import script from "./Hot-article.vue?vue&type=script&lang=js&"
export * from "./Hot-article.vue?vue&type=script&lang=js&"
import style0 from "./Hot-article.vue?vue&type=style&index=0&id=2be6252a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be6252a",
  null
  
)

export default component.exports